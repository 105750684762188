import React from 'react';
import Helmet from 'react-helmet';
import { graphql, StaticQuery } from 'gatsby';
import Layout from '../components/layout';
import Banner from '../components/Banner';
import Gallery from '../components/Gallery';

const Transport = () => (
  <Layout>
    <Helmet
      title="Velgama - Transportas"
      meta={[
        { name: 'description', content: 'Reklama ant transporto' },
      ]}
    />

    <div id="main">
      <Banner h1="Transportas" paragraph="Nuo projekto iki apklijavimo" bannerType="transport-banner" />
      <section id="one" className="spotlights">
        <StaticQuery
          query={galleryQuery}
          render={(data) => <Gallery data={data} />}
        />

      </section>
    </div>
  </Layout>
);

const galleryQuery = graphql`
query transportImages {
    allFile(
      filter: { sourceInstanceName: { eq: "images-transport" } },
      sort: {order: ASC, fields: name}
      ) {
      edges {
        node {
          dir
          name
          childImageSharp{
            fluid (maxWidth: 1200, maxHeight: 1000){
                ...GatsbyImageSharpFluid
              }
          }
        }
      }
    }
  }
`;

export default Transport;
